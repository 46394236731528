import ko from 'knockout'
import {basketTranslations, lazyTrans} from 'components/translations'

import html from 'components/basket-group.html'

export class BasketGroupViewModel {
    constructor (data) {
        this.visible = ko.pureComputed(() => this.items().length > 0)
        this.groupType = data.type
        // todo: this is only temporarily fine
        this.productTypes = this.groupType === 'license' ? ['license', 'evaluation', 'reactivation'] : [this.groupType]
        this._basket = data.basket
        this.isReadOnly = data.readOnly || false
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket))
        this.items = ko.pureComputed(this._getItems.bind(this))
        this.subTitleCode = ko.pureComputed(this._getSubtitleCode.bind(this))
    }

    get name () {
        return lazyTrans(basketTranslations.groupNames[this.groupType])
    }

    _getItems () {
        if (!this.basket()) {
            return []
        }

        return this.basket().combinedLines.filter(line => {
            let type = line.product.type
            if (line.product.period === '1m') {
                type = 'monthly-subscription'
            }
            if (line.product.period === '1y') {
                type = 'yearly-subscription'
            }

            return this.productTypes.includes(type)
        })
    }

    _getSubtitleCode () {
        if (this.groupType === 'yearly-subscription' && this.basket().yearlyDueDate && !this.isReadOnly) {
            return 'yearly-co-term'
        }

        return null
    }
}

ko.components.register('VW.Components.BasketGroup', {
    viewModel: BasketGroupViewModel,
    template: html
})
