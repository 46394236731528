import axios from 'utils/axios'

export function parseUrl (url) {
    const parts = url.split('?')
    const search = (parts[1] || '').split('#')[0] || ''

    return {
        url: parts[0],
        paramsStr: search,
        // todo: did we completely drop IE?
        params: new URLSearchParams(search),
        hash: (parts[1] || '').split('#')[1] || ''
    }
}

export function unparseUrl (url, search, hash) {
    if (search) {
        url += '?' + search
    }

    if (hash) {
        url += '#' + hash
    }
    return url
}

function toUrlSearchParams (params) {
    // convert passed params to URLSearchParams
    if (!(params instanceof URLSearchParams)) {
        let queryParams = []
        Object.keys(params).forEach(function (key) {
            if (typeof params[key] !== 'undefined') {
                queryParams.push(key + '=' + encodeURIComponent(params[key]))
            }
        })
        params = new URLSearchParams(queryParams.join('&'))
    }
    return params
}

export function addUrlQueryParams (url, newParams) {
    const parsedUrl = parseUrl(url)

    // convert passed params to URLSearchParams
    newParams = toUrlSearchParams(newParams)

    let parsedParams = parsedUrl.params
    for (const [key, value] of newParams) {
        if (parsedParams.has(key)) {
            // delete if key is existing already in order to leave only one qp with that name
            parsedParams.delete(key)
        }
        parsedParams.append(key, value)
    }

    return unparseUrl(parsedUrl.url, parsedParams.toString(), parsedUrl.hash)
}

export function updateUrlWithCurrentParams (url, extraParamsToKeep) {
    const PARAMS_TO_KEEP = [...['quote_id', 'access_key', 'security_token', 'basket_id'], ...(extraParamsToKeep || [])]
    const currentParams = new URLSearchParams(window.location.search)
    let params = {}
    for (const param of PARAMS_TO_KEEP) {
        if (currentParams.has(param)) {
            params[param] = currentParams.get(param)
        }
    }

    return addUrlQueryParams(url, params)
}

export function removeUrlQueryParams (url, paramsList) {
    const parsedUrl = parseUrl(url)

    paramsList.forEach(element => {
        delete parsedUrl.params.delete(element)
    })

    return unparseUrl(addUrlQueryParams(parsedUrl.url, parsedUrl.params), null, parsedUrl.hash)
}

export function initAxiosRequestsWithParams () {
    axios.interceptors.request.use(config => {
        config.url = updateUrlWithCurrentParams(config.url)
        return config
    })
}

export function getQueryParams (url) {
    let params = new URLSearchParams(url)
    return Object.fromEntries(params.entries())
}
