import {makeObservable, action, computed} from 'mobx'
import {extendObservable} from './mobx-ko-proxy'
import axios from 'utils/axios'
import moment from 'moment'
import Urls from 'urls'
import PromiseQueue from 'utils/promise-queue'
import {Repository} from './repository'
import {Product} from './product'
import {Distributor} from 'view-models/data/distributor-prefix'
import {basketTranslations, lazyTrans} from 'components/translations'
import PriceUtils from 'utils/price'
import {PromiseRejectionError} from 'utils/errors'

export class BasketMessage {
    constructor (basketId, data) {
        this.basketId = basketId
        this.id = data.id || Date.now() * 10000 + Math.round((Math.random() * 10000))
        this.code = data.code
        this.text = data.text
        this.messageType = data.message_type || 'error'
        this.dismissible = data.dismissible || false
        this.isJsMessage = false

        this.basketMessageClass = 'basket-message-' + this.messageType
    }

    remove () {
        return axios({
            url: Urls.getBasketMessageDetail(this.basketId, this.id),
            method: 'DELETE'
        })
    }
}

export class BasketLine {
    constructor (basketId, data) {
        this.basketId = basketId
        this.id = data.id

        makeObservable(this, {
            _load: action,
            hasDiscount: computed,
            fullPrice: computed,
            finalPrice: computed,
            priceNoTax: computed,
            discountedPriceNoTax: computed,
            price: computed,
            discountedPrice: computed,
            addOnCode: computed,
            isAddOn: computed,
            isReactivation: computed,
            isNetworkSeat: computed,
            quantityText: computed,
            coTermAddOn: computed,
            isScheduleConsistent: computed
        })

        extendObservable(this, {
            product: null,
            quantity: null,
            distributorPrefix: null,
            purchaseCountry: null,
            priceCurrency: null,
            _priceNoTax: null,
            _discountedPriceNoTax: null,
            _price: null,
            _discountedPrice: null,
            displayWithTax: null,
            isAvailable: true,
            messages: null,
            unitPriceExclTax: null,
            schedule: null,
            addOns: []
        })

        this._load(data)
    }

    get hasDiscount () {
        return this.priceNoTax !== this.discountedPriceNoTax
    }

    get fullPrice () {
        return this.displayWithTax ? this.price : this.priceNoTax
    }

    get finalPrice () {
        return this.displayWithTax ? this.discountedPrice : this.discountedPriceNoTax
    }

    get priceNoTax () {
        return this._coTermOrSelf('_priceNoTax')
    }

    get discountedPriceNoTax () {
        return this._coTermOrSelf('_discountedPriceNoTax')
    }

    get price () {
        return this._coTermOrSelf('_price')
    }

    get discountedPrice () {
        return this._coTermOrSelf('_discountedPrice')
    }

    get addOnCode () {
        return this.product.addOnCode
    }

    get isAddOn () {
        return !!this.addOnCode
    }

    get isReactivation () {
        return this.product.isReactivation
    }

    get isNetworkSeat () {
        return this.product.protectionType === 'network'
    }

    get quantityText () {
        return `(${this.quantity}${this.product.quantityUnit})`
    }

    get coTermAddOn () {
        return this.addOns.find(a => a.addOnCode === 'co-term')
    }

    get isScheduleConsistent () {
        return !(this.schedule || []).find(s => s.quantity !== this.quantity)
    }

    _load (data) {
        this.product = Product.create(data.product, data.distributor_prefix, data.show_product_language ? data.product_language : '')
        this.quantity = data.quantity || 0
        this.distributorPrefix = data.distributor_prefix || null
        this.purchaseCountry = data.purchase_country || ''
        this.priceCurrency = data.price_currency
        this._priceNoTax = data.price_excl_tax_excl_discounts
        this._discountedPriceNoTax = data.price_excl_tax
        this._price = data.price_incl_tax_excl_discounts
        this._discountedPrice = data.price_incl_tax
        this.displayWithTax = data.display_with_tax
        this.isAvailable = data.is_available_to_buy
        this.addOns = (data.add_ons || []).map(addOn => new BasketLine(this.basketId, addOn))
        this.messages = (data.messages || []).map(message => new BasketMessage(this.basketId, message))
        this.unitPriceExclTax = data.unit_price_excl_tax
        this.schedule = data.schedule || []
    }

    _coTermOrSelf (propertyName) {
        const coTermAddOn = this.coTermAddOn
        return coTermAddOn ? coTermAddOn[propertyName] : this[propertyName]
    }

    setQuantity (value) {
        return axios({
            url: Urls.getBasketLine(this.basketId, this.id),
            method: 'PATCH',
            data: {'quantity': value}
        })
    }

    delete () {
        return axios({
            url: Urls.getBasketLine(this.basketId, this.id),
            method: 'DELETE'
        })
    }
}

export class BasketVoucherDiscount {
    constructor (basketId, data) {
        this.basketId = basketId

        makeObservable(this, {
            _load: action,
            _setPromoCode: action,
            enterPromoCodeMessage: computed // todo: not sure that needs to be computed
        })

        extendObservable(this, {
            promoCode: null,
            promoCodeErrors: [],
            name: null,
            amount: 0,
            description: null
        })

        this._load(data)
    }

    get enterPromoCodeMessage () {
        return lazyTrans(basketTranslations.enterPromoCodeMsg)
    }

    _load (data) {
        data = data || {}
        this.name = data.name || ''
        this.amount = data.amount
        this.description = data.description || (data.voucher || {}).name || ''
        this.promoCode = (data.voucher && data.voucher.code) || ''
        this.promoCodeErrors = []
    }

    _setPromoCode (code, errors) {
        this.promoCode = code
        this.promoCodeErrors = errors
    }

    add () {
        if (!this.promoCode) {
            return Promise.reject(new PromiseRejectionError())
        }

        return axios({
            url: Urls.basketAddVoucher,
            method: 'POST',
            data: {
                vouchercode: this.promoCode
            }
        })
            .then((result) => {
                this._setPromoCode('', [])
                return result
            })
            .catch(error => {
                let data = error.response.data || {}
                let promoCodeErrors = (data.reason && [data.reason]) ||
                    data.vouchercode || data.non_field_errors || [basketTranslations.unexpectedErrorMsg]
                this._setPromoCode(this.promoCode, promoCodeErrors)
                throw error
            })
    }

    remove () {
        return axios({
            url: Urls.basketRemoveVoucher,
            method: 'POST',
            data: {
                vouchercode: this.promoCode
            }
        })
            .catch(error => {
                // TODO: handle errors. The error status returned is 406
                console.log(error.response)
                throw error
            })
    }
}

export class BasketOfferDiscount {
    constructor (data) {
        makeObservable(this, {
            _load: action
        })

        extendObservable(this, {
            name: null,
            displayName: null,
            amount: 0,
            description: null
        })

        this._load(data)
    }

    _load (data) {
        data = data || {}
        this.name = data.name
        this.displayName = data.display_name
        this.amount = data.amount
        this.description = data.description || ''
    }
}

export class BasketEvent {
    constructor (name, defaultCallback) {
        this.event = name
        this.defaultCallback = defaultCallback

        makeObservable(this, {
            addCallback: action
        })

        extendObservable(this, {
            callbacks: []
        })
    }

    addCallback (callback) {
        this.callbacks.push(callback)
    }

    trigger (data) {
        if (this.callbacks.length === 0) {
            return this.defaultCallback(data)
        }

        this.callbacks.forEach((callback) => callback(data))
    }
}

export class Basket {
    constructor (id, data) {
        this.id = id
        this.generalUnavailableCodes = ['invalid-customer-license-combination']

        makeObservable(this, {
            _load: action,
            _checkForUnavailableProducts: action,
            deleteMessage: action,
            showErrorMessage: action,
            _setLoadingTaxAmount: action,
            _clearTaxEstimate: action,
            setDistributor: action,
            setReloadWithTax: action,
            waitOnPromise: action,
            _removeWaitOnPromise: action,
            messages: computed,
            hasDiscount: computed,
            taxAmountEstimated: computed,
            busy: computed,
            count: computed,
            isEmpty: computed,
            hasUnavailableProduct: computed,
            hasMonthlyProducts: computed,
            hasStandaloneProducts: computed,
            hasNetworkSeats: computed,
            hasNewNetworkSeats: computed,
            isZeroAmount: computed,
            combinedLines: computed,
            messagesEtag: computed
        })

        extendObservable(this, {
            status: null,
            lines: [],
            recommendationLines: [],
            basketMessages: [],
            jsMessages: [],
            distributorPrefix: null,
            etag: null,
            priceCurrency: null,
            priceNoTax: null,
            discountedPriceNoTax: null,
            taxEstimate: null,
            discountedTaxEstimate: null,
            fullPrice: null,
            discountedFullPrice: null,
            totalDiscount: null,
            taxData: null,
            discountedTaxData: null,
            voucherDiscounts: [],
            voucherApplied: false,
            offerDiscounts: [],
            systemDiscounts: [],
            reseller: null,
            purchaseCountry: null,
            yearlyDueDate: null,
            loadingTaxAmount: null,
            reloadWithTax: false,
            isTaxKnown: false,
            waitingOnPromises: [],
            distributor: null
        })

        this.voucherToApply = new BasketVoucherDiscount(this.id)
        this.addProductsQueue = new PromiseQueue(this.reloadBasket.bind(this))
        this.errorEvent = new BasketEvent('error', this._errorHandler.bind(this))
        this.continueShoppingEvent = new BasketEvent('continueShopping', () => {})

        if (data) {
            this._load(data)
        }
    }

    get messages () {
        // todo: shouldn't set the flag as a side effect here
        return [...this.basketMessages, ...this.jsMessages.map(message => {
            message.isJsMessage = true
            return message
        })]
    }

    get messagesEtag () {
        return this.messages.map(message => message.id).join('-')
    }

    get hasDiscount () {
        return this.priceNoTax !== this.discountedPriceNoTax
    }

    get taxAmountEstimated () {
        return !Number.isNaN(this.taxEstimate)
    }

    get busy () {
        return this.waitingOnPromises.length > 0
    }

    get count () {
        return this._getCount()
    }

    get isEmpty () {
        // console.log(this._ko_lines())
        return this.lines.length === 0
    }

    get hasUnavailableProduct () {
        return this.lines.some(line => !line.isAvailable)
    }

    get hasMonthlyProducts () {
        return this.lines.some(line => line.product.isMonthly)
    }

    get hasStandaloneProducts () {
        return this.lines.some(line => !line.isNetworkSeat && !line.isReactivation)
    }

    get hasNetworkSeats () {
        return this.lines.some(line => line.isNetworkSeat)
    }

    get hasNewNetworkSeats () {
        return this.lines.some(line => line.isNetworkSeat && line.product.type !== 'co-term-existing')
    }

    get isZeroAmount () {
        return PriceUtils.isEqual(this.discountedFullPrice, 0)
    }

    get combinedLines () {
        return [
            ...this.lines.filter(line => !this._isInRecommendations(line.id)),
            ...this.recommendationLines
        ]
    }

    on (name, callback) {
        if (name === 'error' && callback) {
            this.errorEvent.addCallback(callback)
        }
    }

    static generateId () {
        // there should really be only one basket at current thinking
        // even if different ids are returned at different times we only care about the current basket
        return 'basket'
    }

    _load (data) {
        // todo: for the whole basket we should aim to not recreate items when they already exist
        // todo: this way bound UI elements will not disappear and re-appear

        // set the actual id, instead of the constant one from generateId. And it can change over time
        this.id = data.id
        this.status = data.status
        this.lines = (data.lines || []).map(lineData => new BasketLine(this.id, lineData))
        this.recommendationLines = (data.recommendations || []).map(lineData => new BasketLine(this.id, lineData))
        this.basketMessages = (data.messages || []).map(message => new BasketMessage(this.id, message))
        this.jsMessages = this.jsMessages.filter(message => message.dismissible)
        this.distributorPrefix = data.distributor_prefix || ''
        this.distributor = data.distributor ? new Distributor(data.distributor.id, data.distributor) : null
        this.priceCurrency = data.currency || ''
        this.priceNoTax = data.total_excl_tax_excl_discounts
        this.discountedPriceNoTax = data.total_excl_tax
        this.taxEstimate = data.total_tax || undefined
        this.discountedTaxEstimate = data.total_tax_excl_discounts || undefined
        this.fullPrice = data.total_incl_tax_excl_discounts || undefined
        this.discountedFullPrice = data.total_incl_tax || undefined
        this.isTaxKnown = data.is_tax_known
        this.totalDiscount = data.total_discount
        this.taxData = data.total_tax_data_excl_discounts
        this.discountedTaxData = data.total_tax_data
        this.voucherDiscounts = (data.grouped_voucher_discounts || []).length !== 0
            ? data.grouped_voucher_discounts.map(voucher => new BasketVoucherDiscount(this.id, voucher))
            : []
        this.voucherApplied = (data.voucher_discounts || []).length !== 0
        this.systemDiscounts = (data.system_discounts || []).map(voucher => new BasketVoucherDiscount(this.id, voucher))
        this.offerDiscounts = (data.offer_discounts || []).map(offer => new BasketOfferDiscount(offer))
        this.reseller = data.reseller
        this.etag = data.etag
        this.purchaseCountry = data.purchase_country
        this.yearlyDueDate = data.yearly_due_date ? moment.utc(data.yearly_due_date).toDate() : null

        this._checkForUnavailableProducts()
    }

    _checkForUnavailableProducts () {
        if (!this.lines.every(line => line.isAvailable)) {
            if (this.jsMessages.every(message => message.code !== 'unavailable-products')) {
                this.jsMessages.push(new BasketMessage(null, {
                    text: this.lines.every(line => line.messages.every(message => !this.generalUnavailableCodes.includes(message.code))) ? lazyTrans(basketTranslations.unavailableProductsRegionMsg) : lazyTrans(basketTranslations.unavailableProductsMsg),
                    code: 'unavailable-products'
                }))
            }
        }
    }

    _isInRecommendations (lineId) {
        return !!this.recommendationLines.find(line => line.id === lineId)
    }

    _getCount () {
        return this.lines.reduce((sum, item) => sum + item.quantity, 0)
    }

    setDistributor (value) {
        this.distributor = value
    }

    setReloadWithTax (value) {
        this.reloadWithTax = value
    }

    addVoucher (voucher) {
        return this.waitOnPromise(voucher.add())
            .then(() => this.reloadBasket())
            .catch(() => {})
    }

    removeVoucher (voucher) {
        return this.waitOnPromise(voucher.remove())
            .catch(() => {})
            .finally(() => this.reloadBasket())
    }

    setQuantity (line, value) {
        let save = line.setQuantity.bind(line, value)
        if (value <= 0) {
            save = line.delete.bind(line)
        }
        return this.waitOnPromise(save())
            .catch(() => {})
            .finally(() => this.reloadBasket())
    }

    deleteMessage (message) {
        if (message.isJsMessage) {
            return new Promise(resolve => {
                this.jsMessages.remove(message)
                return resolve(this)
            })
        }

        return this.waitOnPromise(message.remove())
            .catch(() => {})
            .finally(() => {
                if (this.isEmpty) {
                    this.reloadBasketFromRepo()
                } else {
                    this.reloadBasket()
                }
            })
    }

    waitOnPromise (promise) {
        this.waitingOnPromises.push(promise)
        promise
            .catch(() => {})
            .finally(() => {
                this._removeWaitOnPromise(promise)
            })

        return promise
    }

    _removeWaitOnPromise (promise) {
        this.waitingOnPromises.remove(promise)
    }

    showErrorMessage (message, dismissible) {
        this.jsMessages.unshift(new BasketMessage(null, {
            text: message,
            dismissible: dismissible
        }))
    }

    getOscarError (error) {
        return (error.data && error.data.reason && typeof error.data.reason === 'string') ? error.data.reason : ''
    }

    _errorHandler (error) {
        if (!error) {
            // errors like browser redirect
            return
        }

        error = error.response || error
        const statusCode = parseInt(error.status || 0)
        if (Math.floor(statusCode / 100) === 5 || statusCode === 404) {
            this.showErrorMessage(lazyTrans(basketTranslations.unexpectedErrorMsg), true)
            return
        }

        if (Math.floor(statusCode / 100) === 4) {
            var errorMsg = error[0] || error.error || error.detail || error?.data?.detail || this.getOscarError(error)
            this.showErrorMessage(errorMsg || lazyTrans(basketTranslations.unexpectedErrorMsg), true)
        }
    }

    _getAffiliate () {
        return new URLSearchParams(window.location.search).get('aff')
    }

    addProduct (productCode, quantity, distributorPrefix, purchaseCountry, licenseId, expireDate) {
        const data = {
            url: Urls.basketAddProduct,
            method: 'POST',
            data: {
                quantity: Number(quantity) || 1,
                product: productCode,
                distributor_prefix: distributorPrefix,
                purchase_country: purchaseCountry,
                affiliate_code: this._getAffiliate(),
                license_id: licenseId,
                expire_date: expireDate
            }
        }

        return this.waitOnPromise(this.addProductsQueue.enqueue(axios.bind(null, data)))
            .catch(this.errorEvent.trigger.bind(this.errorEvent))
    }

    reloadBasketFromRepo () {
        return this.waitOnPromise(Basket.repo.loadAll(null, true))
            .catch(this.errorEvent.trigger.bind(this.errorEvent))
    }

    _setLoadingTaxAmount (value) {
        this.loadingTaxAmount = value
        if (!value && this.isEmpty) {
            this.taxEstimate = null
        }
    }

    _clearTaxEstimate () {
        this.taxEstimate = NaN
        this.fullPrice = undefined
        this.discountedFullPrice = undefined
    }

    prepareForSubmission () {
        if (this.isEmpty) {
            this._clearTaxEstimate()
            return Promise.resolve()
        }

        const data = {
            url: Urls.basketPrepareForSubmission(this.id),
            method: 'GET'
        }

        this._setLoadingTaxAmount(true)

        const submitResult = this.waitOnPromise(axios(data))
        submitResult
            .then((result) => this._load(result.data))
            .catch(() => {})
            .finally(() => this._setLoadingTaxAmount(false))

        return submitResult
    }

    reloadBasket () {
        if (this.reloadWithTax) {
            return this.prepareForSubmission()
                .catch(() => {
                    let promise = this.reloadBasketFromRepo()
                    promise
                        .finally(() => {
                            if (!this.isTaxKnown) {
                                // Do not show total prices and total tax if prepare-for-submission fails due to invalid
                                // organization address
                                this._clearTaxEstimate()
                            }
                        })
                    return promise
                }) // this will reload the basket without tax info
        } else {
            return this.reloadBasketFromRepo()
        }
    }

    removeAllProducts () {
        if (this.isEmpty) {
            return
        }

        const data = {
            url: Urls.removeAllProducts,
            method: 'POST'
        }

        return this.waitOnPromise(axios(data))
            .catch(() => {})
            .finally(() => this.reloadBasket())
    }

    continueShopping () {
        this.continueShoppingEvent.trigger()
    }
}

Basket.repo = new Repository(Basket, Urls.basket)
